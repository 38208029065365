import React from 'react';
import {
    Target,
    Users,
    Building,
    TrendingUp,
    TrendingDown,
    Mail,
    RefreshCw,
    Share2,
    Bell,
    Briefcase,
    AlertTriangle,
    Minus
} from 'lucide-react';
import { LineChart, Line, ResponsiveContainer } from 'recharts';

// Risk Assessment Parameters
const riskParameters = {
    timeline: {
        onTrack: 0.8,
        atRisk: 0.5,
        behind: 0.3
    },
    progress: {
        onTrack: 0.9,
        atRisk: 0.7,
        behind: 0.5
    },
    momentum: {
        onTrack: 1.0,
        atRisk: 0.8,
        behind: 0.6
    }
};

// Mock Data
const goalsData = {
    company: {
        name: "2024 Strategic Objectives",
        goals: [
            {
                title: "Increase Market Share",
                target: "25% growth in UK client base",
                progress: 68,
                startDate: "2024-01-01",
                endDate: "2024-12-31",
                totalDays: 365,
                daysPassed: 10,
                momentum: 0.072,
                required: 0.068,
                trend: "up",
                previousProgress: [62, 64, 65, 68],
                warning: null
            },
            {
                title: "Client Retention",
                target: "90% retention rate",
                progress: 75,
                startDate: "2024-01-01",
                endDate: "2024-12-31",
                totalDays: 365,
                daysPassed: 10,
                momentum: 0.18,
                required: 0.25,
                trend: "down",
                previousProgress: [82, 80, 77, 75],
                warning: "Declining retention in tech sector"
            }
        ]
    },
    department: {
        name: "IP Department Goals",
        goals: [
            {
                title: "Portfolio Growth",
                target: "500 new trademark applications",
                progress: 42,
                startDate: "2024-01-01",
                endDate: "2024-12-31",
                totalDays: 365,
                daysPassed: 10,
                momentum: 1.2,
                required: 1.37,
                trend: "down",
                previousProgress: [48, 46, 44, 42],
                warning: "Application backlog growing"
            }
        ]
    }
};

const metrics = {
    targets: {
        reached: 45,
        total: 60,
        trend: "+12% vs last month"
    },
    emails: {
        sent: 1250,
        openRate: "32.5%",
        responseRate: "12.8%"
    },
    conversions: {
        meetings: 28,
        deals: 12,
        rate: "42.8%"
    }
};

const TrendIndicator = ({ trend, className = "w-4 h-4" }: any) => {
    switch (trend) {
        case "up":
            return <TrendingUp className={`${className} text-green-500`} />;
        case "down":
            return <TrendingDown className={`${className} text-red-500`} />;
        default:
            return <Minus className={`${className} text-gray-500`} />;
    }
};

const calculateRisk = (goal: any) => {
    const timelineScore = (goal.daysPassed / goal.totalDays);
    const progressScore = (goal.progress / 100);
    const momentumScore = (goal.momentum / goal.required);

    const overallScore = (timelineScore + progressScore + momentumScore) / 3;

    if (overallScore >= riskParameters.progress.onTrack) return "on-track";
    if (overallScore >= riskParameters.progress.atRisk) return "at-risk";
    return "behind";
};

const getStatusColor = (status: any) => {
    switch (status) {
        case "on-track":
            return "bg-green-100 text-green-800";
        case "at-risk":
            return "bg-yellow-100 text-yellow-800";
        case "behind":
            return "bg-red-100 text-red-800";
        default:
            return "bg-gray-100 text-gray-800";
    }
};

const RiskMonitor = ({ goal }: any) => {
    const status = calculateRisk(goal);
    const getStatusDetails = (status: any) => {
        switch (status) {
            case "on-track":
                return {
                    color: "text-green-600",
                    icon: "✓",
                    message: "On Track"
                };
            case "at-risk":
                return {
                    color: "text-yellow-600",
                    icon: "⚠",
                    message: goal.warning || "Needs Attention"
                };
            case "behind":
                return {
                    color: "text-red-600",
                    icon: "!",
                    message: goal.warning || "Behind Schedule"
                };
            default:
                return {
                    color: "text-gray-600",
                    icon: "?",
                    message: "Unknown"
                };
        }
    };

    const statusDetails = getStatusDetails(status);

    return (
        <div className="space-y-2">
            <div className={`flex items-center gap-2 ${statusDetails.color}`}>
                <span className="text-lg">{statusDetails.icon}</span>
                <span className="text-sm">{statusDetails.message}</span>
            </div>
            {goal.warning && (
                <div className="text-xs text-red-600 flex items-center gap-1">
                    <AlertTriangle className="w-3 h-3" />
                    {goal.warning}
                </div>
            )}
        </div>
    );
};

const ProgressChart = ({ data }: any) => {
    const chartData = data.map((value: any, index: any) => ({
        week: index + 1,
        value: value
    }));

    return (
        <div className="h-10">
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={chartData}>
                    <Line
                        type="monotone"
                        dataKey="value"
                        stroke="#2563eb"
                        strokeWidth={2}
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

const GoalCard = ({ title, goals }: any) => {
    return (
        <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
            <div>
                <div className="flex items-center gap-2 flex-wrap">
                    {title === "Company Goals" && <Building className="w-5 h-5" />}
                    {title === "Department Goals" && <Users className="w-5 h-5" />}
                    {title === "Team Goals" && <Users className="w-5 h-5" />}
                    {title}
                </div>
            </div>
            <div>
                <div className="space-y-4">
                    {goals.map((goal: any, index: any) => (
                        <div key={index} className="p-3 border rounded-lg mt-3">
                            <div className="flex justify-between items-start mb-2">
                                <div>
                                    <div className="flex items-center gap-2">
                                        <h4 className="font-medium">{goal.title}</h4>
                                        <TrendIndicator trend={goal.trend} />
                                    </div>
                                    <p className="text-sm text-gray-500">{goal.target}</p>
                                </div>
                                <div className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm ${getStatusColor(calculateRisk(goal))}`}>
                                    {goal.progress}%
                                </div>
                            </div>
                            <div className="space-y-2">
                                <div className="w-full bg-gray-200 rounded-full h-2">
                                    <div
                                        className="bg-blue-500 rounded-full h-2"
                                        style={{ width: `${goal.progress}%` }}
                                    />
                                </div>
                                <ProgressChart data={goal.previousProgress} />
                                <div className="flex justify-between items-start">
                                    <RiskMonitor goal={goal} />
                                    <div className="text-xs text-gray-500 text-right">
                                        Current Momentum: {(goal.momentum / goal.required * 100).toFixed(1)}% of required
                                        {goal.momentum < goal.required && (
                                            <div className="text-red-500">
                                                {((goal.required - goal.momentum) / goal.required * 100).toFixed(1)}% below target
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const Main = () => {
    return (
        <div className="space-y-6 p-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                    <div className="p-4">
                        <div className="flex items-center justify-between">
                            <div>
                                <p className="text-sm text-gray-500">Targets Reached</p>
                                <p className="text-2xl font-bold">{metrics.targets.reached}/{metrics.targets.total}</p>
                                <p className="text-xs text-green-600">{metrics.targets.trend}</p>
                            </div>
                            <Target className="w-8 h-8 text-blue-400" />
                        </div>
                    </div>
                </div>
                <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                    <div className="p-4">
                        <div className="flex items-center justify-between">
                            <div>
                                <p className="text-sm text-gray-500">Email Performance</p>
                                <p className="text-2xl font-bold">{metrics.emails.openRate}</p>
                                <p className="text-xs text-gray-500">Response: {metrics.emails.responseRate}</p>
                            </div>
                            <Mail className="w-8 h-8 text-blue-400" />
                        </div>
                    </div>
                </div>
                <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                    <div className="p-4">
                        <div className="flex items-center justify-between">
                            <div>
                                <p className="text-sm text-gray-500">Conversion Rate</p>
                                <p className="text-2xl font-bold">{metrics.conversions.rate}</p>
                                <p className="text-xs text-gray-500">
                                    {metrics.conversions.meetings} meetings → {metrics.conversions.deals} deals
                                </p>
                            </div>
                            <TrendingUp className="w-8 h-8 text-green-400" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <GoalCard title="Company Goals" goals={goalsData.company.goals} />
                <GoalCard title="Department Goals" goals={goalsData.department.goals} />
            </div>
        </div>
    );
};

export default Main;