import { useState } from "react"
import { useDomainAnalysis } from "hooks/useDomainAnalysis";
const DomainChecker = () => {
    const { checkDomainsStatus,downloadReport } = useDomainAnalysis()
    const [domainsData, setDomainsData] = useState("")
    const [domainsStatus, setDomainsStatus] = useState([])
    const [downloadReportStatus, setDownloadReportStatus] = useState(false);
    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const { domains_status } = await checkDomainsStatus(domainsData?.split("\n"))
        setDomainsStatus(domains_status)
    }
    const downloadReportData = async () => {
        setDownloadReportStatus(true);
        try {
          await downloadReport({filename:"domain_checker"}, 'Domain Checker');
        } catch (error) {
        } finally {
          setDownloadReportStatus(false);
        }
      };
    return (
        <div className="container mx-auto mt-10 ">
            <h3 className="text-2xl text-black font-bold mb-5">Domain Checker</h3>
            <form onSubmit={handleFormSubmit}>
                <div className="block sm:flex justify-between">
                    <textarea
                        id="input"
                        rows={10}
                        className="h-full focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                        placeholder="Enter Domain (separated by line break)"
                        required
                        value={domainsData}
                        onChange={(e) => setDomainsData(e.target.value)}
                    ></textarea>
                </div>
                <div className="flex mt-8 justify-center">
                    <button className="text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5">Submit</button>
                    <button type="button"
                    onClick={() => downloadReportData()}
                    disabled={!domainsStatus?.length || downloadReportStatus} className="hover:bg-opacity-50 text-[#dcdcdc] bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 ml-3 disabled:opacity-50 disabled:pointer-events-none"> {downloadReportStatus ? "Downloading..." : "Download All Status"}</button>
                </div>
            </form>
            {domainsStatus?.length ?
                <div className="mt-5 relative overflow-x-auto">
                    <table className="w-full text-sm text-left border-main bg-green">
                        <thead className="text-xs text-white uppercase bg-green border border-main sticky">
                            <tr>
                                <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Domain</th>
                                <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {domainsStatus?.map((item:any, index)=>(
                                <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main" key={index}>
                                <td className="px-6 py-4 text-black border border-main">{item.Domain}</td>
                                <td className="px-6 py-4 text-black border border-main">{item['Availability Status']}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                : null}
        </div>
    )
}
export default DomainChecker