import React, { useState, useEffect } from 'react';
import {
    Calendar, PoundSterling, AlertTriangle, Check, Briefcase, FileText,
    Settings, TrendingUp, Globe, Shield, Scale, AlertCircle, RefreshCw,
    Trash2, Edit, Plus, Archive, Clock, Filter, ArrowUpRight, Link2,
    Download, Eye, EyeOff
} from 'lucide-react';

// Priority categories
const actionCategories: any = {
    high_priority: {
        color: 'border-red-500',
        textColor: 'text-red-700',
        bgColor: 'bg-red-50',
        label: 'High Priority'
    },
    medium_priority: {
        color: 'border-yellow-500',
        textColor: 'text-yellow-700',
        bgColor: 'bg-yellow-50',
        label: 'Medium Priority'
    },
    low_priority: {
        color: 'border-blue-500',
        textColor: 'text-blue-700',
        bgColor: 'bg-blue-50',
        label: 'Low Priority'
    }
};

const TimelineCard = ({ item, onEdit, onDelete }: any) => (
    <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
        <div className="p-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="md:col-span-2">
                    <div className="flex justify-between items-start">
                        <div>
                            <h3 className="font-bold text-lg">{item.action}</h3>
                            <p className="text-sm text-gray-600">Stage: {item.stage}</p>
                        </div>
                        <div className="flex space-x-2">
                            <button onClick={() => onEdit(item.id)} className="p-1 hover:bg-gray-100 rounded">
                                <Edit className="h-4 w-4 text-gray-500" />
                            </button>
                            <button onClick={() => onDelete(item.id)} className="p-1 hover:bg-gray-100 rounded">
                                <Trash2 className="h-4 w-4 text-red-500" />
                            </button>
                        </div>
                    </div>

                    <div className="mt-4 space-y-3">
                        <div className="flex flex-wrap gap-2">
                            {Object.entries(item.cost || {}).map(([type, amount]: any) => (
                                <span key={type} className="px-2 py-1 bg-gray-100 rounded text-sm">
                                    {type}: £{amount.toLocaleString()}
                                </span>
                            ))}
                        </div>

                        {item.documents?.length > 0 && (
                            <div className="flex flex-wrap gap-2">
                                {item.documents.map((doc: any, idx: any) => (
                                    <span key={idx} className="flex items-center space-x-1 text-sm text-blue-600">
                                        <FileText className="h-4 w-4" />
                                        <span>{doc}</span>
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="md:col-span-1 border-l pl-4">
                    <div className="space-y-3">
                        <div className="flex items-center space-x-2">
                            <Calendar className="h-4 w-4 text-gray-500" />
                            <span className="text-sm">Deadline: {item.deadline}</span>
                        </div>

                        <div className="flex items-center space-x-2">
                            <span className={`px-2 py-1 rounded-full text-xs ${item.status === 'Completed' ? 'bg-[#d1fae5] text-[#065f46]' :
                                    item.status === 'In Progress' ? 'bg-blue-100 text-blue-800' :
                                        'bg-gray-100 text-gray-800'
                                }`}>
                                {item.status}
                            </span>
                        </div>

                        {item.notes && (
                            <p className="text-sm text-gray-600">{item.notes}</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const TimelineView = ({ caseData, onAddAction, onEditAction, onDeleteAction }: any) => {
    const [filterStatus, setFilterStatus] = useState('all');
    const [showArchived, setShowArchived] = useState(false);

    const filteredTimeline = caseData?.timeline?.filter((item: any) => {
        if (!showArchived && item.status === 'Archived') return false;
        if (filterStatus !== 'all' && item.status !== filterStatus) return false;
        return true;
    }) || [];

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <div className="flex space-x-2">
                    <button
                        onClick={onAddAction}
                        className="flex items-center space-x-2 px-4 py-2 bg-green text-white rounded"
                    >
                        <Plus className="h-4 w-4" />
                        <span>Add Action</span>
                    </button>
                    <select
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                        className="px-3 py-2 border rounded"
                    >
                        <option value="all">All Status</option>
                        <option value="Planned">Planned</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Completed">Completed</option>
                    </select>
                </div>
                <div className="flex items-center space-x-4">
                    <button
                        onClick={() => setShowArchived(!showArchived)}
                        className="flex items-center space-x-2 text-gray-600"
                    >
                        {showArchived ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                        <span>{showArchived ? 'Hide Archived' : 'Show Archived'}</span>
                    </button>
                    <span className="text-sm text-gray-600">
                        {filteredTimeline.length} actions
                    </span>
                </div>
            </div>

            <div className="space-y-4">
                {filteredTimeline.map((item: any) => (
                    <TimelineCard
                        key={item.id}
                        item={item}
                        onEdit={onEditAction}
                        onDelete={onDeleteAction}
                    />
                ))}
            </div>
        </div>
    );
};

const BudgetView = ({ caseData }: any) => {
    const totalSpent: any = Object.values(caseData.budgets?.departmental || {}).reduce((a: any, b: any) => a + b, 0);
    const remaining = (caseData.budgets?.allocated || 0) - totalSpent;

    return (
        <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                    <div className="p-4">
                        <div className="flex items-center space-x-2">
                            <PoundSterling className="h-4 w-4 text-green-500" />
                            <h3 className="font-bold">Total Budget</h3>
                        </div>
                        <p className="text-2xl font-bold mt-2">
                            £{caseData.budgets?.allocated.toLocaleString()}
                        </p>
                        <div className="mt-2 text-sm">
                            <span className="text-gray-600">Remaining: </span>
                            <span className={remaining < 0 ? 'text-red-600' : 'text-green-600'}>
                                £{remaining.toLocaleString()}
                            </span>
                        </div>
                    </div>
                </div>

                <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                    <div className="p-4">
                        <div className="flex items-center space-x-2">
                            <TrendingUp className="h-4 w-4 text-blue-500" />
                            <h3 className="font-bold">Spend Rate</h3>
                        </div>
                        <p className="text-2xl font-bold mt-2">
                            {((totalSpent / caseData.budgets?.allocated) * 100).toFixed(1)}%
                        </p>
                    </div>
                </div>

                <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                    <div className="p-4">
                        <div className="flex items-center space-x-2">
                            <AlertTriangle className="h-4 w-4 text-red-500" />
                            <h3 className="font-bold">Risk Level</h3>
                        </div>
                        <p className="text-2xl font-bold mt-2 text-red-500">
                            £{Math.abs(caseData.brandRisk).toLocaleString()}
                        </p>
                    </div>
                </div>
            </div>

            <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                <div>
                    <span className='font-bold'>Departmental Allocation</span>
                </div>
                <div>
                    <div className="space-y-4">
                        {Object.entries(caseData.budgets?.departmental || {}).map(([dept, amount]: any) => (
                            <div key={dept} className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    {dept === 'legal' && <Scale className="h-4 w-4 text-blue-500" />}
                                    {dept === 'domains' && <Globe className="h-4 w-4 text-green-500" />}
                                    {dept === 'obp' && <Shield className="h-4 w-4 text-purple-500" />}
                                    {dept === 'dispute_management' && <AlertCircle className="h-4 w-4 text-orange-500" />}
                                    <span className="capitalize">{dept.replace('_', ' ')}</span>
                                </div>
                                <div className="flex items-center space-x-4">
                                    <span className="font-semibold">£{amount.toLocaleString()}</span>
                                    <div className="w-32 h-2 bg-gray-200 rounded-full">
                                        <div
                                            className="h-full bg-green rounded-full"
                                            style={{
                                                width: `${(amount / caseData.budgets.allocated) * 100}%`
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const DisputeDashboard = () => {
    const [caseData, setCaseData] = useState({
        id: 1,
        title: 'Brand Protection Case',
        status: 'Active',
        brandRisk: -75000,
        budgets: {
            allocated: 200000,
            departmental: {
                legal: 80000,
                domains: 40000,
                obp: 45000,
                dispute_management: 35000
            }
        },
        timeline: [
            {
                id: 1,
                stage: 'preliminary',
                action: 'Cease & Desist',
                status: 'Completed',
                priority: 'high_priority',
                cost: {
                    legal: 3000,
                    admin: 500,
                    external: 1500
                },
                deadline: '2024-12-15',
                documents: ['letter.pdf', 'evidence.pdf'],
                notes: 'Initial enforcement action'
            },
            {
                id: 2,
                stage: 'negotiation',
                action: 'Settlement Discussion',
                status: 'In Progress',
                priority: 'medium_priority',
                cost: {
                    legal: 5000,
                    admin: 1000,
                    external: 2000
                },
                deadline: '2025-01-30',
                documents: ['proposal.pdf'],
                notes: 'Ongoing negotiations'
            }
        ]
    });

    const handleAddAction = () => {
        const newAction = {
            id: caseData.timeline.length + 1,
            stage: 'preliminary',
            action: 'New Action',
            status: 'Planned',
            priority: 'low_priority',
            cost: {
                legal: 1000,
                admin: 500,
                external: 500
            },
            deadline: new Date().toISOString().split('T')[0],
            documents: [],
            notes: 'New action added'
        };

        setCaseData(prev => ({
            ...prev,
            timeline: [...prev.timeline, newAction]
        }));
    };

    const handleEditAction = (id: any) => {
        // Implementation for editing action
        console.log('Editing action:', id);
    };

    const handleDeleteAction = (id: any) => {
        setCaseData(prev => ({
            ...prev,
            timeline: prev.timeline.filter(item => item.id !== id)
        }));
    };
    const [activeTab, setActiveTab] = useState('timeline');

    return (
        <div className="container mx-auto mt-10 pb-5">
            <div className="flex justify-between items-center mb-6">
                <div>
                    <h1 className="text-2xl font-bold">Dispute Management Dashboard</h1>
                    <p className="text-gray-600">Case: {caseData.title}</p>
                </div>
                <button className="flex items-center space-x-2 px-4 py-2 bg-green text-white rounded">
                    <Settings className="h-4 w-4" />
                    <span>Settings</span>
                </button>
            </div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">Select your country</label>
                <select className="focus:outline-none w-full rounded-2xl py-2 mb-3 px-4 border-4 border-main text-primary"
                    onChange={(e) => setActiveTab(e.target.value)}
                >
                    <option value="timeline">Timeline</option>
                    <option value="budget">Budget</option>
                </select>
            </div>
            <ul className="hidden text-sm font-medium text-center text-[#6B7280] rounded-lg shadow sm:flex">
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('timeline')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] rounded-s-lg focus:outline-none ${activeTab === 'timeline' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}>Timeline</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('budget')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'budget' ? 'active bg-[#F3F4F6]' : 'bg-white'}`}>Budget</a>
                </li>
            </ul>
            <div className='mt-5'>
            {activeTab === 'timeline' && (
                <TimelineView
                    caseData={caseData}
                    onAddAction={handleAddAction}
                    onEditAction={handleEditAction}
                    onDeleteAction={handleDeleteAction}
                />
            )}
            {activeTab === 'budget' && (
                <BudgetView caseData={caseData} />
            )}
            </div>
        </div>
    );
};

export default DisputeDashboard;