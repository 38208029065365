import React, { useState } from 'react';
import {
    Scale,
    Search,
    Globe,
    AlertTriangle,
    DollarSign,
    BarChart,
    FileSearch,
    Clock,
    CheckCircle,
    XCircle,
    RefreshCw
} from 'lucide-react';

const Main = () => {
    const [activeAnalysis, setActiveAnalysis] = useState(null);
    const [analysisResults, setAnalysisResults] = useState<any>({});

    // Mock analysis types and their parameters
    const analysisTypes: any = {
        trademark: {
            name: "Trademark Analysis",
            icon: <Scale className="w-4 h-4" />,
            metrics: [
                "Portfolio Size",
                "Geographic Coverage",
                "Class Distribution",
                "Opposition History",
                "Renewal Status"
            ],
            timeEstimate: "10-15 minutes"
        },
        domain: {
            name: "Domain Analysis",
            icon: <Globe className="w-4 h-4" />,
            metrics: [
                "Domain Portfolio",
                "Brand Matching",
                "Geographic Distribution",
                "Domain Age",
                "Usage Analysis"
            ],
            timeEstimate: "5-10 minutes"
        },
        disputes: {
            name: "Dispute Status",
            icon: <AlertTriangle className="w-4 h-4" />,
            metrics: [
                "Active Disputes",
                "Historical Conflicts",
                "Resolution Rates",
                "Risk Assessment",
                "Market Impact"
            ],
            timeEstimate: "15-20 minutes"
        },
        valuation: {
            name: "Full Valuation",
            icon: <DollarSign className="w-4 h-4" />,
            metrics: [
                "IP Portfolio Value",
                "Market Position",
                "Growth Potential",
                "Industry Comparison",
                "Risk Factors"
            ],
            timeEstimate: "25-30 minutes"
        }
    };

    // Mock company to analyze
    const targetCompany = {
        name: "InnovateAI Ltd",
        industry: "Artificial Intelligence",
        size: "Mid-Market",
        region: "UK/EU"
    };

    // Mock analysis results
    const mockResults: any = {
        trademark: {
            score: 85,
            summary: "Strong trademark portfolio with global coverage",
            details: {
                portfolioSize: "127 marks",
                mainClasses: "9, 42, 45",
                geographicStrength: "Strong in UK/EU",
                riskAreas: "Limited APAC coverage"
            }
        },
        domain: {
            score: 92,
            summary: "Comprehensive domain protection",
            details: {
                totalDomains: "45 active",
                brandCoverage: "95%",
                topLevels: ".com, .ai, .uk, .eu",
                gaps: "Minor regional gaps"
            }
        },
        disputes: {
            score: 78,
            summary: "Low dispute risk profile",
            details: {
                activeDisputes: "None",
                historicalCases: "3 resolved",
                riskLevel: "Low",
                recommendations: "Monitor AI sector"
            }
        },
        valuation: {
            score: 88,
            summary: "Strong IP portfolio with high growth potential",
            details: {
                totalValue: "£15M-20M",
                growthRate: "25% YoY",
                industryPosition: "Top Quartile",
                futureProjection: "Positive"
            }
        }
    };

    const requestAnalysis = (type: any) => {
        setActiveAnalysis(type);
        // Simulate API call
        setTimeout(() => {
            setAnalysisResults({
                ...analysisResults,
                [type]: mockResults[type]
            });
            setActiveAnalysis(null);
        }, 2000);
    };

    const getScoreColor = (score: any) => {
        if (score >= 90) return "bg-green-100 text-green-800";
        if (score >= 80) return "bg-blue-100 text-blue-800";
        if (score >= 70) return "bg-yellow-100 text-yellow-800";
        return "bg-red-100 text-red-800";
    };

    return (
        <div className="space-y-6">
            {/* Analysis Request Dashboard */}
            <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                <div>
                    <div className="flex items-center gap-2">
                        <FileSearch className="w-5 h-5" />
                        <span className='font-bold'>IP Analysis Hub</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <div>
                            Request detailed analysis for {targetCompany.name}
                        </div>
                        <div className='bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm'>
                            {targetCompany.industry} • {targetCompany.size}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                        {Object.entries(analysisTypes).map(([key, type]: any) => (
                            <div key={key} className="border rounded-lg p-4">
                                <div className="flex justify-between items-start mb-4">
                                    <div className="flex items-center gap-2">
                                        {type.icon}
                                        <h3 className="font-medium">{type.name}</h3>
                                    </div>
                                    {analysisResults[key] && (
                                        <div className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm ${getScoreColor(analysisResults[key].score)}`}>
                                            Score: {analysisResults[key].score}
                                        </div>
                                    )}
                                </div>

                                <div className="space-y-2 mb-4">
                                    {type.metrics.map((metric: any, i: any) => (
                                        <div key={i} className="flex items-center gap-2 text-sm text-gray-500">
                                            <div className="w-1 h-1 bg-gray-400 rounded-full"></div>
                                            {metric}
                                        </div>
                                    ))}
                                </div>

                                <div className="flex justify-between items-center">
                                    <div className="flex items-center gap-1 text-sm text-gray-500">
                                        <Clock className="w-4 h-4" />
                                        {type.timeEstimate}
                                    </div>
                                    <button
                                        className={`flex items-center gap-2 px-4 py-2 rounded-lg ${analysisResults[key] ? "bg-green text-white" : "border border-green text-green"}`}
                                        onClick={() => requestAnalysis(key)}
                                        disabled={activeAnalysis === key}
                                    >
                                        {activeAnalysis === key ? (
                                            <RefreshCw className="w-4 h-4 animate-spin mr-2" />
                                        ) : analysisResults[key] ? (
                                            "Refresh Analysis"
                                        ) : (
                                            "Request Analysis"
                                        )}
                                    </button>
                                </div>

                                {analysisResults[key] && (
                                    <div className="mt-4 pt-4 border-t">
                                        <p className="text-sm mb-2">{analysisResults[key].summary}</p>
                                        <div className="space-y-1">
                                            {Object.entries(analysisResults[key].details).map(([key, value]: any) => (
                                                <div key={key} className="text-sm flex justify-between">
                                                    <span className="text-gray-500">{key}:</span>
                                                    <span className="font-medium">{value}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Analysis Overview */}
            <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                <div>
                    <div className="flex items-center gap-2">
                        <BarChart className="w-5 h-5" />
                        <span className='font-bold'>Analysis Overview</span>
                    </div>
                </div>
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-3">
                        {Object.entries(analysisResults).map(([key, result]: any) => (
                            <div key={key} className="p-4 border rounded-lg">
                                <div className="flex items-center justify-between mb-2">
                                    <span className="text-sm text-gray-500">{analysisTypes[key].name}</span>
                                    <div className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm ${getScoreColor(result.score)}`}>
                                        Score: {result.score}
                                    </div>
                                </div>
                                <p className="text-sm">{result.summary}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;