import { useState } from "react"
import { useFindLeads } from "hooks/useFindLeads"
import Loader from "page/DashBoard/Loader"
const FindPeople = () => {
    const [peopleSearchForm, setPeopleSearchForm] = useState<any>({
        name: "",
        organization_name: "",
    })
    const { peopleSearch } = useFindLeads()
    const [results, setResults] = useState<any>(null)
    const [isLoading, setIsLoading] = useState<any>(false)
    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setIsLoading(true)
            const { person } = await peopleSearch(peopleSearchForm)
            setResults(person)
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
        }
    }
    return (
        <div className="container mx-auto mt-10 ">
            <h3 className="text-2xl text-black font-bold mb-5">Search People</h3>
            <form onSubmit={handleFormSubmit}>
                <div className="mt-3">
                    <input type="text"
                        value={peopleSearchForm.name}
                        onChange={(e) => setPeopleSearchForm({ ...peopleSearchForm, name: e.target.value })}
                        className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl focus:outline-none" placeholder="Enter Person Name" />
                </div>
                <div className="mt-3">
                    <input type="text"
                        value={peopleSearchForm.organization_name}
                        onChange={(e) => setPeopleSearchForm({ ...peopleSearchForm, organization_name: e.target.value })}
                        className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl mb-3 focus:outline-none" placeholder="Enter Organization Name" />
                </div>
                <div className="mt-5">
                    <button className="text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5">Submit</button>
                </div>
            </form>
            {isLoading && <Loader />}
            {results ?
                <div className="mt-5 relative overflow-x-auto">
                    <table className="w-full text-sm text-left border-main bg-green">
                        <thead className="text-xs text-white uppercase bg-green border border-main sticky">
                            <tr>
                                <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Name</th>
                                <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Email</th>
                                <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Phone</th>
                                <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Linkedin</th>
                                <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">City</th>
                                <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Country</th>
                                <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main">
                                <td className="px-6 py-4 text-black border border-main">{results.name}</td>
                                <td className="px-6 py-4 text-black border border-main">{results.email ? results.email : '-'}</td>
                                <td className="px-6 py-4 text-black border border-main">{results?.organization?.phone ? results?.organization?.phone : '-'}</td>
                                <td className="px-6 py-4 text-black border border-main">{results.linkedin_url ? results.linkedin_url : '-'}</td>
                                <td className="px-6 py-4 text-black border border-main">{results.city ? results.city : '-'}</td>
                                <td className="px-6 py-4 text-black border border-main">{results.country ? results.country : '-'}</td>
                                <td className="px-6 py-4 text-black border border-main"><button className="text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5">Add to List</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                : null}
        </div>
    )
}
export default FindPeople