import { useState } from "react";
import {
  dashboard,
  analytics,
  settings,
  report,
  search,
  hamburger,
  whiteLogo,
  noTextLogo,
  trademarkIcon,
  dashboardIcon,
  reminderIcon,
  fileContractIcon,
  analysis,
  valuation,
  product,
  conflict
} from "assests";
import { Link } from "react-router-dom";
import ham_icon from "../../assests/icons/hamburger_icon.png";
import { toast } from "react-toastify";
import NavItemWithChildren from "./components/NavItemWithChildren";
const LeftSideBar = () => {
  const [open, setOpen] = useState(false);
  const Menus = [
    { title: "Conflict Check", src: conflict, link: "/conflict-check" },
    { title: "Ai Analysis", src: dashboard, link: "/" },
    { title: "Schedule ", src: report, link: "/schedule" },
    { title: "Search", src: search, link: "#" },
    { title: "Blockchain Certification", src: analytics, link: "https://app.bernstein.io/abion", target: true },
    { title: "Domain Analysis", src: search, link: "/domain-analysis" },
    { title: "DNS Twister", src: search, link: "/dns-twister" },
    { title: "Certificate Search", src: search, link: "/certificate-search" },
    { title: "Find Leads", src: search, link: "/find-leads" },
    { title: "BIMI", src: dashboard, children: [
      { title: "BIMI Analysis", src: dashboardIcon, link: "/bimi/bimi-analysis" },
      { title: "VMC Campaign Tool", src: dashboardIcon, link: "/bimi/vmc-campaign-tool" },
    ]
   },
    { title: "Aittorney", src: trademarkIcon, link: "https://a-itorney.vercel.app/", target: true },
    { title: "Gap Fill", src: analytics, link: "/gap-fill" },
    { title: "Specification Management", src: analytics, link: "/specification-management" },
    {
      title: "Licensing", src: settings, children: [
        { title: "Dashboard", src: dashboardIcon, link: "/licensing/dashboard" },
        { title: "Smart Contract", src: fileContractIcon, link: "/licensing/smart-contract" },
        { title: "Reminder Notification", src: reminderIcon, link: "/licensing/reminder-notification-system" },
      ]
    },
    {
      title: "Dispute Management", src: settings, children: [
        { title: "Dashboard 1", src: dashboardIcon, link: "/dispute-management/dashboard-1" },
        { title: "Dashboard 2", src: dashboardIcon, link: "/dispute-management/dashboard-2" }
      ]
    },
    {
      title: "CRM", src: settings, children: [
        { title: "Boost Action", src: dashboardIcon, link: "/crm/boost-action" },
        { title: "Geo Leads", src: dashboardIcon, link: "/crm/geo-leads" },
        { title: "Goals Dashboard", src: dashboardIcon, link: "/crm/goals-dashboard" },
        { title: "IP Analysis Hub", src: dashboardIcon, link: "/crm/ip-analysis-hub" }
      ]
    },
    {
      title: "Valuation Calculators", src: settings, children: [
        { title: "Currency Analysis", src: analysis, link: "/calculator/analysis" },
        { title: "Enhanced Valuation", src: valuation, link: "/calculator/enhanced-valuation" },
        { title: "Comprehensive Valuation", src: valuation, link: "/calculator/comprehensive-valuation" }
      ]
    },
    {
      title: "Detailed Valuation PE", src: settings, children: [
        { title: "Product", src: product, link: "/detailed-valuation/product" },
        { title: "EBIDTA Impact Analysis", src: analysis, link: "/detailed-valuation/ebidta-analysis" },
      ]
    },
    { title: "Trawl Leads", src: analytics, link: "/trawl-section" },
    { title: "Logout", src: settings, link: "#" },

  ];

  const logoutUser = () => {
    localStorage.removeItem("user");
    toast.success("Logout successful");
    window.location.reload();
  };

  return (
    <div className={`bg-[#033d44] ${open ? "mr-[16rem]" : "mr-[5rem] xl:mr-[4rem]"}`}>
      <div
        className={`fixed overflow-auto top-0 bottom-0 ${open ? "w-[240px]" : "w-20"} p-5 pt-8 duration-300 bg-[#033d44]`}
      >
        <img
          src={ham_icon}
          className={`absolute cursor-pointer right-4 top-[2.3rem] w-5  ${!open && "rotate-180 right-8 "
            }`}
          style={{ filter: "invert(100%)" }}
          onClick={() => setOpen(!open)}
        />

        <div className="flex gap-x-2 items-center">
          <Link to="/">
            <img
              src={whiteLogo}
              className={`${!open && "hidden"} -mt-[1.8rem]`}
              width={150}
              height={150}
            />
          </Link>
        </div>
        <ul className={`${open ? "-mt-5 h-[100vh]" : "pt-6"}`}>
          {Menus.map((Menu, index) => (
            <>
              {Menu?.children ? <NavItemWithChildren open={open} data={Menu} /> :
                <Link
                  to={Menu.link}
                  key={index}
                  {...(Menu.title === "Logout" && {
                    onClick: () => {
                      logoutUser();
                    },
                  })}
                  target={Menu.target ? '_blank' : ''}
                >
                  <li
                    className={`flex rounded-md px-2 pb-2 pt-1 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2`}
                  >
                    <img
                      src={Menu.src}
                      className="w-5 h-5 object-contain"
                      style={{ filter: "invert(100%)" }}
                    />

                    <span
                      className={`${!open && "hidden"
                        } text-[#dcdcdc] origin-left duration-200`}
                    >
                      {Menu.title}
                    </span>
                  </li>
                </Link>
              }
            </>
          ))}
          <li>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LeftSideBar;
