import { ChartData, Form1Schema ,Form2Schema, TextLogoSchema} from "types";
import { Column } from "react-table";
import { Columns } from "types";
import { LoginSchema,ContactSchema,TradeMarkSchema } from "types";
import { turkey,united_kingdom,united_state,denmark,sweden,switzerland,eurpeon_union,norway,china,germany,uae } from "assests";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PDFDocument } from "pdf-lib";
export const PATHS = {
  CONFLICTCHECK: "/conflict-check",
  HOME: "/",
  SUBSCRIBER: "/",
  ALERTS: "/",
  REPORTS: "/",
  CHANNELS: "/",
  PUNNYCODE: "/domain-analysis",
  DNSTWISTER: "/dns-twister",
  FINDLEADS:"/find-leads",
  CERTIFICATESEARCH:"/certificate-search",
  BLOCKCHAINCERTIFICATION: "/blockchain-certification",
  FORGETPASSWORD: "/forget-password",
  LOGIN: "/login",
  VERIFICATION:'/verification',
  SCHEDULE:'/schedule',
  TRAWLSECTION:'/trawl-section',
  GAPFILL:'/gap-fill',
  SPECIFICATIONMANAGEMENT:'/specification-management',
  BIMIANALYSIS:'/bimi/bimi-analysis',
  LICENSINGDASHBOARD:'/licensing/dashboard',
  LICENSINGREMINDERSYSTEM:'/licensing/reminder-notification-system',
  LICENSINGSMARTCONTRACT:'/licensing/smart-contract',
  LICENSINGTRADEMARKLICENSE:'/licensing/trademark-license',
  CALCULATORANALYSIS:'/calculator/analysis',
  CALCULATORENHANCEDVALUATION:'/calculator/enhanced-valuation',
  CALCULATORCOMPREHENSIVEVALUATION:'/calculator/comprehensive-valuation',
  DETAILEDVALUATIONPRODUCT:'/detailed-valuation/product',
  DETAILEDEBIDTAIMPACTANALYSIS:'/detailed-valuation/ebidta-analysis',
  VMCCAMPAIGNTOOL:'/bimi/vmc-campaign-tool',
  DISPUTEMANAGEMENTDASHBOARD1:'/dispute-management/dashboard-1',
  DISPUTEMANAGEMENTDASHBOARD2:'/dispute-management/dashboard-2',
  CRMBOOSTACTION:'/crm/boost-action',
  CRMGEOLEADS:'/crm/geo-leads',
  CRMGOALSDASHBOARD:'/crm/goals-dashboard',
  CRMIPANALYSISHUB:'/crm/ip-analysis-hub'
};
export type AddCommentButton={
  hasCountryDistribution:Boolean
  hasMissingCoverage:Boolean,
  hasMostVulnerableNonUseTrademarks:Boolean
  hasTop5Class:Boolean,
  hasExpiringSoonTrademarksbyQuater:Boolean
  hasStatusDistribution:Boolean
  hasMissingProductTermInCountry:Boolean
  hasMissingProductTermInTradeMark:Boolean
  hasTradeMarkMostMissingClasses:Boolean
  hasTopCountriesMissingMostTopMark:Boolean
  hasCountryRepresentatives:Boolean
  hasMostFrequentMarks:Boolean
  
}
export const CONTENT={
  countryRepresentativeText: "",
  missingProductTermInCountryText: "",
  missingProductTermInTrademarkText: "",
  mostFrequentMarksText: "",
  tradeMarkMostMissingClassesText: "",
  topCountriesMissingMostTopMarkText: "",
  statusDistributionText: "",
  editorContent: "",
  editorContentIntro: "",
  editorContentTop5Classes: "",
  editorContentMissingCoverage: "",
  editorContentVulnerable: "",
  expiringSoonTrademarksbyQuater: "",
}

export const ADDCOMMENTSBUTTON:AddCommentButton={
  hasCountryDistribution:false,
  hasMissingCoverage:false,
  hasMostVulnerableNonUseTrademarks:false,
  hasTop5Class:false,
  hasExpiringSoonTrademarksbyQuater:false,
  hasStatusDistribution:false,
  hasMissingProductTermInCountry:false,
  hasMissingProductTermInTradeMark:false,
  hasTradeMarkMostMissingClasses:false,
  hasTopCountriesMissingMostTopMark:false,
  hasCountryRepresentatives:false,
  hasMostFrequentMarks:false
}
export type reportImageContent={
  countryDistributionImage:string | null | undefined,
  countryRepresentativesImage:string | null| undefined,
  missingProductTermInCountryImage:string | null| undefined,
  mostFrequentMarksImage:string | null| undefined,
  missingProductTermInTrademarkImage:string | null| undefined,
  tradeMarkMostMissingClassesImage:string | null| undefined,
  topCountriesMissingMostTopMarkImage:string | null| undefined,
  mostFrequentMarks:string | null| undefined,
  vulnerableImageURL:string | null| undefined,
  MissingCoverageImageURL:string | null| undefined,
  Top5ClassesImageURL:string | null| undefined,
groupByQuarterImageURL:string | null| undefined,
statusDistributionImageURL:string | null| undefined,
missingGraphImageURL:string | null| undefined,
}
export const REPORTIMAGECONTENT:reportImageContent={
  countryDistributionImage: null,
  countryRepresentativesImage: null,
  missingProductTermInCountryImage: null,
  mostFrequentMarksImage: null,
  missingProductTermInTrademarkImage: null,
  tradeMarkMostMissingClassesImage: null,
  topCountriesMissingMostTopMarkImage: null,
  mostFrequentMarks: null,
  vulnerableImageURL: null,
  MissingCoverageImageURL: null,
  Top5ClassesImageURL: null,
  groupByQuarterImageURL: null,
  statusDistributionImageURL:null,
  missingGraphImageURL:null
}
// export const reportRefs = {
//   statusDistributionRef: useRef<HTMLDivElement>(null),
//   countryRepresentativesRef: useRef<HTMLDivElement>(null),
//   mostFrequentMarksRef: useRef<HTMLDivElement>(null),
//   topCountriesMissingMostTopMarkRef: useRef<HTMLDivElement>(null),
//   missingProductTermInCountryRef: useRef<HTMLDivElement>(null),
//   missingProductTermInTrademarkRef: useRef<HTMLDivElement>(null),
//   tradeMarkMostMissingClassesRef: useRef<HTMLDivElement>(null),
//  graphRef : useRef<HTMLDivElement>(null),
// vulnerableRef : useRef<HTMLDivElement>(null),
// top5ClassesRef : useRef<HTMLDivElement>(null),
// groupByQuarterRef : useRef<HTMLDivElement>(null),
// };

export const TRADEMARKFORM:TradeMarkSchema={
  trademarkLogo : "Logo Trademark",
  wordTrademark:'',
  fileUpload: "",
  service:'',
  webUrl:"",
  serviceDesc:'',
  selectClass:'1',
  selectClassNote:'',
  trademarkCount:'1',

}
export const LOGININITIALVALUES: LoginSchema = {
  email: "",
  password: "",
};
export const CONTACTINITIALVALUES: ContactSchema = {
  firstName:'',
  lastName:'',
  email: "",
  note:'',
  phoneNumber:''
};
export const REPORTMODAL={
  modal1: false,
  modal2: false,
  modal3: false,
  modal4: false,
  modal5: false,
  modal6: false,
  modal7: false,
  modal8: false,
  modal9: false,
  modal10: false,
  modal11: false,
  modal12: false,
  modal13: false,
}
export const FORM1INITIALVALUES:Form1Schema={
  servies:'',
  webUrl:'',
}
export const FORM2INITIALVALUES:Form2Schema={
   selectClass:'1',
   addGoods:''
}

export const TEXTLOGOINITIALVALUES:TextLogoSchema={
  textLogo:''
}
// export const TRADEMARKINITIALVALUES:={
//   trademarkType:"",
//   wordTrademark?:'',
//   fileUpload:"",
//   webUrl?:'',
//   serviceDesc?:"",
//   class?:'',
//   addServices?:'',
//   searchItem?:''

// }
export const data = [
  {
    label: "january",
    value: 100,
  },
  {
    label: "january",
    value: 100,
  },
  {
    label: "febuary",
    value: 170,
  },
  {
    label: "march",
    value: 200,
  },
  {
    label: "april",
    value: 190,
  },
  {
    label: "may",
    value: 100,
  },
];

export const NAVITEMS = [
  {
    title: "Dashboard",
    to: PATHS.HOME,
  },
  {
    title: "Subscriber",
    to: PATHS.SUBSCRIBER,
  },
  {
    title: "NEWS ALERTS",
    to: PATHS.ALERTS,
  },
  {
    title: "Channels",
    to: PATHS.CHANNELS,
  },
  {
    title: "Reports",
    to: PATHS.REPORTS,
  },
];
const line1Data: ChartData = {
  labels: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  values: [15, 22, 28, 18, 25, 30, 35, 40, 45, 50, 55, 60],
};
const line2Data: ChartData = {
  labels: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  values: [13, 35, 38, 40, 42, 45, 48, 50, 52, 55, 58, 60],
};

const line3Data: ChartData = {
  labels: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  values: [15, 42, 30, 45, 38, 50, 55, 60, 65, 70, 75, 80],
};

export const LINECHARTDATA: ChartData[] = [line1Data, line2Data, line3Data];

export const linedata1 = {
  labels: [
    "Undefined Logo",
    "Evergreen Logo",
    "One-Stop",
    "SELEFA logo",
    "EVERCARE",
  ],
  values: [6, 7, 8, 7, 6],
};
export const StatusDistribution = [
  {
    label: "Valid",
    value: 83,
  },
  {
    label: "Invalid",
    value: 4,
  },
  {
    label: "Expired",
    value: 10,
  },
];
export const RepresenterData = [
  {
    country: "Norway PS",
    value: 6,
  },
  {
    country: "Denmark PVS",
    value: 3,
  },
  {
    country: "European Union EUIPO",
    value: 2,
  },
  {
    country: "Sweden PRV",
    value: 2,
  },
  {
    country: "United Kingdom IPO",
    value: 2,
  },
  {
    country: "WIPO - Norway",
    value: 2,
  },
  {
    country: "WIPO - Switzerland",
    value: 2,
  },
  {
    country: "WIPO - United Kingdom",
    value: 2,
  },
  {
    country: "Finland PRH",
    value: 1,
  },
  {
    country: "Switzerland IPI",
    value: 1,
  },
  {
    country: "WIPO - Russian Federation",
    value: 1,
  },
  {
    country: "WIPO - Ukraine",
    value: 1,
  },
];

export const TradeMarkFrequencyData = [
  {
    label: "Undefined Logo",
    value: 12,
  },
  {
    label: "Evercare Logo",
    value: 19,
  },
  {
    label: "One-Stop",
    value: 3,
  },
  {
    label: "SELEFA Logo",
    value: 5,
  },
  {
    label: "EVERCARE",
    value: 2,
  },
];

export const TradeMarkStatusData = [
  {
    label: "Valid",
    value: 83,
  },
  {
    label: "Invalid",
    value: 4,
  },
  {
    label: "Expired",
    value: 10,
  },
];
export const CountryDistributionData = [
  {
    country: "European Union EUIPO",
    value: 23,
  },
  {
    country: "Sweden PRV",
    value: 23,
  },
  {
    country: "Norway PS",
    value: 20,
  },
  {
    country: "United Kingdom IPO",
    value: 15,
  },
  {
    country: "Denmark PVS",
    value: 7,
  },
  {
    country: "WIPO - Russian Federation",
    value: 7,
  },
  {
    country: "Finland PRH",
    value: 5,
  },
  {
    country: "WIPO - Norway",
    value: 4,
  },
  {
    country: "WIPO - Ukraine",
    value: 4,
  },
  {
    country: "WIPO - Switzerland",
    value: 4,
  },
  {
    country: "WIPO - Russian Federation",
    value: 2,
  },
  {
    country: "WIPO - United Kingdom",
    value: 2,
  },
  {
    country: "Switzerland IPI ",
    value: 1,
  },
];
export const COUNTRYREPRESENTATION: Column<Columns>[] = [
  {
    Header: "Country",
    accessor: "col1" as keyof Columns,
  },
  {
    Header: "Representative",
    accessor: "col2" as keyof Columns,
  }
];
export const MOSTMISSINGCLASSES: Column<Columns>[] = [
  {
    Header: "Total Missing Classes",
    accessor: "col1" as keyof Columns,
  },
  {
    Header: "Trademark",
    accessor: "col2" as keyof Columns,
  },
  {
    Header: "Logo",
    accessor: "logoSection" as keyof Columns,
  },
];
export const COUNTRYREPRESENTATION2: Column<Columns>[] = [
  {
    Header: "No of Missing Trade Marks",
    accessor: "col2" as keyof Columns,
  },
  {
    Header: "Country",
    accessor: "col1" as keyof Columns,
  }
];
export const CHARTDATACOLUMN: Column<Columns>[] = [
  {
    Header: "Application Date",
    accessor: (row: any) => row["App. date"],
  },
  {
    Header: "Application Number",
    accessor: (row: any) => row["App. no."],
  },
  {
    Header: "Classes",
    accessor: "Classes" as keyof Columns,
  },
  {
    Header: "Country",
    accessor: "Country" as keyof Columns,
  },
  {
    Header: "Expiration Date",
    accessor: (row: any) => row["Exp. date"],
  },
  {
    Header: "ID",
    accessor: "ID" as keyof Columns,
  },
  {
    Header: "Owner",
    accessor: "Owner" as keyof Columns,
  },
  {
    Header: "Registration Date",
    accessor: (row: any) => row["Reg. date"],
  },
  {
    Header: "Registration Number",
    accessor: (row: any) => row["Reg. no."],
  },
  {
    Header: "Representative",
    accessor: "Representative" as keyof Columns,
  },
  {
    Header: "Standardized Country",
    accessor: "Standardized Country" as keyof Columns,
  },
  {
    Header: "Status",
    accessor: "Status" as keyof Columns,
  },
  {
    Header: "Trademark",
    accessor: "Trade Mark" as keyof Columns,
  },
  {
    Header: "Logo",
    accessor: "Image Path" as keyof Columns,
  },
];
export const MISSINGPRODUCTTERMINTRADEMARKDATACOLUMN: Column<Columns>[] = [
  {
    Header: "Missing Count",
    accessor: "col1" as keyof Columns,
  },
  {
    Header: "Class",
    accessor: "col2" as keyof Columns,
  },
  {
    Header: "Product Term Text",
    accessor: "col3" as keyof Columns,
  },
  {
    Header: "Trademark",
    accessor: "col4" as keyof Columns,
  },
  {
    Header: "Logo",
    accessor: "logoSection" as keyof Columns,
  },
];
export const MISSINGPRODUCTTERMINCOUNTRYDATACOLUMN: Column<Columns>[] = [
  {
    Header: "Missing Count",
    accessor: "col1" as keyof Columns,
  },
  {
    Header: "Product Term",
    accessor: "col2" as keyof Columns,
  },
  {
    Header: "Product Term Text",
    accessor: "col3" as keyof Columns,
  },
  {
    Header: "Country",
    accessor: "col4" as keyof Columns,
  },
];
export const UNIQUEOWNERSCOLUMNS: Column<Columns>[] = [
  {
    Header: "Unique Owner Name",
    accessor: "col1" as keyof Columns,
  },
  {
    Header: "Count",
    accessor: "col2" as keyof Columns,
  }
];
export const MOSTFREQUENCYCOLUMN: Column<Columns>[] = [
  {
    Header: "Application Date",
    accessor: (row: any) => row["App. date"],
  },
  {
    Header: "Application Number",
    accessor: (row: any) => row["App. no."],
  },
  {
    Header: "Classes",
    accessor: "Classes" as keyof Columns,
  },
  {
    Header: "Country",
    accessor: "Country" as keyof Columns,
  },
  {
    Header: "Expiration Date",
    accessor: (row: any) => row["Exp. date"],
  },
  {
    Header: "ID",
    accessor: "ID" as keyof Columns,
  },
  {
    Header: "Owner",
    accessor: "Owner" as keyof Columns,
  },
  {
    Header: "Registration Date",
    accessor: (row: any) => row["Reg. date"],
  },
  {
    Header: "Registration Number",
    accessor: (row: any) => row["Reg. no."],
  },
  {
    Header: "Representative",
    accessor: "Representative" as keyof Columns,
  },

  {
    Header: "Status",
    accessor: "Status" as keyof Columns,
  },
  {
    Header: "Trademark",
    accessor: "Trade Mark" as keyof Columns,
  },
  {
    Header: "Logo",
    accessor: "logoSection" as keyof Columns,
  },
];

export const MOSTFREQUENTMARKS: Column<Columns>[] = [
  {
    Header: "Trademark",
    accessor: "col1" as keyof Columns,
  },
  {
    Header: "Country Coverage",
    accessor: "col2" as keyof Columns,
  },
  {
    Header: "Logo",
    accessor: "logoSection" as keyof Columns,
  },
];
export const MOSTVULNERABLENONUSETRADEMARKS: Column<Columns>[] = [
  {
    Header: "Classes",
    accessor: "col1" as keyof Columns,
  },
  {
    Header: "Country",
    accessor: "col2" as keyof Columns,
  },
  {
    Header: "Reg. date",
    accessor: "col3" as keyof Columns,
  },
  {
    Header: "Reg. no.",
    accessor: "col4" as keyof Columns,
  },
  {
    Header: "Trademark",
    accessor: "col5" as keyof Columns,
  },
  {
    Header: "Logo",
    accessor: "logoSection" as keyof Columns,
  },
];
export const MOSTMISSINGTRADEMARKSCOUNTRIES: Column<Columns>[] = [
  {
    Header: "Top 10 Countries",
    accessor: "col1" as keyof Columns,
  },
  {
    Header: "Trademarks Count",
    accessor: "col2" as keyof Columns,
  },
  {
    Header: "Trademarks Not Present In",
    accessor: "col3" as keyof Columns,
  },
  {
    Header: "Logo",
    accessor: "logoSection" as keyof Columns,
  },
];
export const CLASSESTABLE: Column<Columns>[] = [
  {
    Header: "App. date",
    accessor: "col1" as keyof Columns,
  },
  {
    Header: "Country",
    accessor: "col2" as keyof Columns,
  },
  {
    Header: "Exp. date",
    accessor: "col3" as keyof Columns,
  },
  {
    Header: "Owner",
    accessor: "col4" as keyof Columns,
  },
  {
    Header: "Reg. date",
    accessor: "col5" as keyof Columns,
  },
  {
    Header: "Status",
    accessor: "col6" as keyof Columns,
  },
  {
    Header: "Trademark",
    accessor: "col7" as keyof Columns,
  },
  {
    Header:"Logo",
    accessor: "logoSection" as keyof Columns,
  }
];

export const STATUSTABLE: Column<Columns>[] = [
  {
    Header: "App. date",
    accessor: "col1" as keyof Columns,
  },
  {
    Header: "Country",
    accessor: "col2" as keyof Columns,
  },
  {
    Header: "Exp. date",
    accessor: "col3" as keyof Columns,
  },
  {
    Header: "Owner",
    accessor: "col4" as keyof Columns,
  },
  {
    Header: "Reg. date",
    accessor: "col5" as keyof Columns,
  },
  {
    Header: "Status",
    accessor: "col6" as keyof Columns,
  },
  {
    Header: "Trademark",
    accessor: "col7" as keyof Columns,
  },
  {
    Header: "Logo",
    accessor: "logoSection" as keyof Columns,
  },
];
export const COUNTRIES=[
  {
    country:"United Kingdom",
    flag:united_kingdom,
    price:"$321"
  },
  {
    country:"United State",
    flag:united_state,
    price:"$321"
  },
  {
    country:"Sweden",
    flag:sweden,
    price:"$321"
  },
  {
    country:"Switzerland",
    flag:switzerland,
    price:"$321"
  },
  {
    country:"Norway",
    flag:norway,
    price:"$321"
  },
  {
    country:"Denmark",
    flag:denmark,
    price:"$321"
  },
  {
    country:"Turkey",
    flag:turkey,
    price:"$321"
  },
  {
    country:"China",
    flag:china,
    price:"$321"
  },
  {
    country:"Germany",
    flag:germany,
    price:"$321"
  },
  {
    country:"UAE",
    flag:uae,
    price:"$321"
  },
  {
    country:"Europeon Union",
    flag:eurpeon_union,
    price:"$321"
  },
  
]
export const ADDTRADEMARK=[{
  id:1,
  productId:321,
  class:3
},
{
  id:2,
  productId:21,
  class:2
}
]
export const ADDTRADEMARKCOLUMN : Column<Columns>[] = [
  {
    Header: "ID",
    accessor: "id"as keyof Columns ,
  },
  {
    Header: "Product Id",
    accessor: "productId" as keyof Columns,
  },
  {
    Header: "Class",
    accessor: "class" as keyof Columns,
  },
];
    
export const OPTIONS = [
  { value: 'Country Distribution', label: 'Country Distribution' },
  { value: 'Expiring Soon Trademarks by Quarter', label: 'Trade Marks Expiring Soon by Quarter' },
  { value: 'Missing Coverage', label: 'Missing Coverage' },
  { value: 'Most Vulnerable Non-Use Trademarks', label: 'Trade Marks Vulnerable to Cancellation for Non-Use' },
  { value: 'Top 5 Class', label: 'Top 5 Class' },
  { value: 'Status Distribution', label: 'Status Distribution' },
  { value: 'Missing Product Term In Country', label: 'Product Terms Missing Per Country' },
  { value: 'Missing Product Term In TradeMark', label: 'Product Terms Missing Per Trademark' },
  { value: 'TradeMark Most Missing Classes', label: 'Trade Marks Missing the Most Classes' },
  { value: 'Top Countries Missing Most Top Mark', label: 'Top 10 Countries Missing Top 10 Trade Marks' },
  { value: 'Country Representatives', label: 'Country Representatives' },
  { value: 'Most Frequent Marks ', label: 'Most Frequent Marks' },

];




