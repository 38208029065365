import axios from "axios";
// import { getUserInfoFromLocalStorage } from "libs/cache";
/**
 * axios instance
 */
export const api = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_BASE_URL
});
// request header
api.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response parse
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    throw error;
  }
);
