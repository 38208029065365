import React, { useState } from 'react';
import {
  Zap,
  Users,
  Calendar,
  AlertCircle,
  Target,
  Clock,
  CheckCircle,
  Flag,
  TrendingUp
} from 'lucide-react';

const BoostActions = () => {
  const [selectedPriority, setSelectedPriority] = useState('high');
  const [selectedTeam, setSelectedTeam] = useState([]);

  // Mock boost types
  const boostTypes = {
    revenue: {
      name: "Revenue Sprint",
      description: "2-week intensive focus on closing high-value opportunities",
      requirements: [
        "Minimum 3 team members",
        "£150k+ opportunity pipeline",
        "75%+ probability leads"
      ],
      recommendedFor: "End of quarter catch-up"
    },
    portfolio: {
      name: "Portfolio Expansion",
      description: "Focus on expanding services with existing clients",
      requirements: [
        "Minimum 2 team members",
        "Active client base",
        "Cross-sell opportunities"
      ],
      recommendedFor: "Client growth initiatives"
    },
    newBusiness: {
      name: "New Business Drive",
      description: "Accelerated outreach to high-value prospects",
      requirements: [
        "Minimum 4 team members",
        "Qualified lead list",
        "Available meeting slots"
      ],
      recommendedFor: "Market expansion"
    }
  };

  // Mock team suggestions
  const teamSuggestions = [
    {
      name: "Sarah Parker",
      expertise: "Enterprise Deals",
      availability: "70%",
      successRate: "92%"
    },
    {
      name: "James Wilson",
      expertise: "Technical Sales",
      availability: "85%",
      successRate: "88%"
    }
  ];

  return (
    <div className="space-y-6">
      {/* Boost Campaign Creator */}
      <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
        <div>
          <div className="flex items-center gap-2">
            <Zap className="w-5 h-5" />
            <span className='font-bold'>Create Boost Campaign</span>
          </div>
          Configure an accelerated revenue campaign
        </div>
        <div className="space-y-6 mt-3">
          {/* Campaign Types */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {Object.entries(boostTypes).map(([key, type]) => (
              <div key={key} className="border rounded-lg p-4 cursor-pointer hover:border-blue-500">
                <h3 className="font-medium mb-2">{type.name}</h3>
                <p className="text-sm text-gray-500 mb-3">{type.description}</p>
                <div className="space-y-2">
                  <div className="text-sm">
                    <div className='bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full'>
                      {type.recommendedFor}
                    </div>
                  </div>
                  <div className="text-xs text-gray-500">
                    Requirements:
                    <ul className="mt-1 space-y-1">
                      {type.requirements.map((req, index) => (
                        <li key={index} className="flex items-center gap-1">
                          <CheckCircle className="w-3 h-3" />
                          {req}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Team Assembly */}
          <div className="border rounded-lg p-4">
            <h3 className="font-medium mb-3">Recommended Team Members</h3>
            <div className="space-y-3">
              {teamSuggestions.map((member, index) => (
                <div key={index} className="flex justify-between items-center p-3 bg-gray-50 rounded-lg">
                  <div>
                    <div className="font-medium">{member.name}</div>
                    <div className="text-sm text-gray-500">{member.expertise}</div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="text-sm">
                      <div>Available: {member.availability}</div>
                      <div>Success: {member.successRate}</div>
                    </div>
                    <button
                      className={`flex items-center space-x-2 px-6 py-3 text-white rounded-lg shadow-sm text-white bg-primary`}
                    >
                      Add to Team
                    </button>

                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Action Scheduler */}
          <div className="border rounded-lg p-4">
            <h3 className="font-medium mb-3">Campaign Schedule</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <div className="text-sm font-medium mb-2">Duration</div>

                <select className="focus:outline-none w-full rounded-2xl py-2 mb-3 px-4 border-4 border-main text-primary">
                  <option value="1week">1 Week Sprint</option>
                  <option value="2weeks">2 Week Sprint</option>
                  <option value="4weeks">4 Week Sprint</option>
                </select>
              </div>
              <div>
                <div className="text-sm font-medium mb-2">Target Value</div>
                <select className="focus:outline-none w-full rounded-2xl py-2 mb-3 px-4 border-4 border-main text-primary">
                  <option value="100k">£100,000</option>
                  <option value="200k">£200,000</option>
                  <option value="500k">£500,000</option>
                </select>
              </div>
            </div>
          </div>

          {/* Campaign Automation */}
          <div className="border rounded-lg p-4">
            <h3 className="font-medium mb-3">Campaign Automation</h3>
            <div className="space-y-3">
              <div className="flex items-center justify-between p-2 bg-gray-50 rounded">
                <div className="flex items-center gap-2">
                  <Calendar className="w-4 h-4" />
                  <span>Auto-schedule high-value meetings</span>
                </div>
                <button
                  className={`flex items-center space-x-2 px-6 py-3 text-white rounded-lg shadow-sm text-white bg-primary`}
                >
                  Configure
                </button>
              </div>
              <div className="flex items-center justify-between p-2 bg-gray-50 rounded">
                <div className="flex items-center gap-2">
                  <AlertCircle className="w-4 h-4" />
                  <span>Daily progress alerts</span>
                </div>
                <button
                  className={`flex items-center space-x-2 px-6 py-3 text-white rounded-lg shadow-sm text-white bg-primary`}
                >
                  Configure
                </button>
              </div>
              <div className="flex items-center justify-between p-2 bg-gray-50 rounded">
                <div className="flex items-center gap-2">
                  <TrendingUp className="w-4 h-4" />
                  <span>Performance tracking</span>
                </div>
                <button
                  className={`flex items-center space-x-2 px-6 py-3 text-white rounded-lg shadow-sm text-white bg-primary`}
                >
                  Configure
                </button>
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <button
              className={`flex items-center space-x-2 px-6 py-3 text-white rounded-lg shadow-sm text-white bg-primary`}
            >
              Save as Draft
            </button>
            <button
              className={`flex items-center gap-2 space-x-2 px-6 py-3 text-white rounded-lg shadow-sm text-white bg-primary`}
            >
              <Zap className="w-4 h-4" />

              Launch Campaign
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoostActions;