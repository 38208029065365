import { useState } from "react"
import Loader from "page/DashBoard/Loader"
import { useDomainAnalysis } from "hooks/useDomainAnalysis"
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
const Main = () => {
    const { getCertificateDetails } = useDomainAnalysis()
    const [domain, setDomain] = useState<any>("")
    const [isLoading, setIsLoading] = useState<any>(false)
    const [results, setResults] = useState<any>(null)
    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setResults(null)
        try {
            setIsLoading(true)
            const data = await getCertificateDetails(domain)
            setResults(data)
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
        }
    }
    const exportCertificateProviderCountResultsToCSV = () => {
        const headers = [
            "Issuer Name",
            "Count"
        ].join(",");
        const rows = Object.keys(results.issuer_counts_data).map((key: string) => [
            key,
            results.issuer_counts_data[key]
        ].join(","));
        const csvContent = [headers, ...rows].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Certificate Provider Count Results.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const exportCertificateSearchResultsToCSV = () => {

        const headers = [
            "Crt.sh ID",
            "Logged At",
            "Not Before",
            "Not After",
            "Common Name",
            "Matching Identities",
            "Issuer Name"
        ].join(",");

        const rows = results.table_data.map((result: any) => [
            result.crt_id,
            result.logged_at,
            result.not_before,
            result.not_after,
            result.common_name,
            `"${result.matching_identities.replace(/\n/g, " | ")}"`,
            result.issuer_name
        ].join(","));
        console.log(rows)
        const csvContent = [headers, ...rows].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Certificate Search Results.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <div className="container mx-auto mt-10 ">
            <h3 className="text-2xl text-black font-bold mb-5">Certificate Search</h3>
            <form onSubmit={handleFormSubmit}>
                <div className="mt-3">
                    <input type="text"
                        value={domain}
                        onChange={(e) => setDomain(e.target.value)}
                        className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl focus:outline-none" placeholder="Enter an Identity (Domain Name, Organization Name, etc)" />
                </div>
                <div className="flex items-center justify-between flex-wrap">
                    <button
                        disabled={isLoading}
                        className="mt-3 text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 disabled:pointer-events-none"
                    >
                        Search
                    </button>
                </div>
            </form>
            {isLoading && <Loader />}
            {results && (
                <>
                    <div className="text-right">
                        <button
                            className="mt-3 text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 disabled:pointer-events-none"
                            onClick={exportCertificateSearchResultsToCSV}
                            type={"button"}
                        >
                            Export Certificate Search Results
                        </button>
                    </div>
                    <div className="relative overflow-x-auto">
                        <table className="text-sm text-left border-main bg-green mt-5 pb-5 table-fixed w-full">
                            <thead className="text-xs text-white uppercase bg-green border border-main sticky">
                                <tr>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Crt.sh ID</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Logged At</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Not Before</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Not After</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Common Name</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Matching Identities</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Issuer Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {results.table_data.map((result: any, index: number) => (
                                    <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main" key={index}>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.crt_id}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.logged_at}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.not_before}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.not_after}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.common_name}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.matching_identities}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.issuer_name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="text-right mt-5">
                        <button
                            className="mt-3 text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 disabled:pointer-events-none"
                            onClick={exportCertificateProviderCountResultsToCSV}
                            type={"button"}
                        >
                            Export Certificate Provider Count Results
                        </button>
                    </div>
                    <div className="relative overflow-x-auto">
                        <table className="text-sm text-left border-main bg-green mt-5 pb-5 table-fixed w-full">
                            <thead className="text-xs text-white uppercase bg-green border border-main sticky">
                                <tr>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Issuer Name</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(results.issuer_counts_data).map((key: any, index: number) => (
                                    <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main" key={index}>
                                        <td className="p-3 border border-main text-sm">{key}</td>
                                        <td className="p-3 border border-main text-sm">{results.issuer_counts_data[key]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-5 pb-5">
                        <h3 className="text-xl text-black font-bold mb-3">Summary:</h3>
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                        >
                            {results.gpt_response}
                        </ReactMarkdown>
                        {results.upcoming_renewals.length > 0 &&
                            <>
                            <h3 className="text-xl text-black font-bold mt-5">Upcoming Expiring Certificates:</h3>
                            <div className="relative overflow-x-auto">
                        <table className="text-sm text-left border-main bg-green mt-5 pb-5 table-fixed w-full">
                            <thead className="text-xs text-white uppercase bg-green border border-main sticky">
                                <tr>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Crt.sh ID</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Logged At</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Not Before</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Not After</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Common Name</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Matching Identities</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Issuer Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {results.upcoming_renewals.map((result: any, index: number) => (
                                    <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main" key={index}>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.crt_id}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.logged_at}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.not_before}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.not_after}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.common_name}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.matching_identities}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.issuer_name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                            </>
                        }
                         {results.upcoming_renewals.length  > 0 &&
                            <>
                            <h3 className="text-xl text-black font-bold mt-5">Expired Certificates:</h3>
                            <div className="relative overflow-x-auto">
                        <table className="text-sm text-left border-main bg-green mt-5 pb-5 table-fixed w-full">
                            <thead className="text-xs text-white uppercase bg-green border border-main sticky">
                                <tr>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Crt.sh ID</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Logged At</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Not Before</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Not After</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Common Name</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Matching Identities</th>
                                    <th className="p-3 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Issuer Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {results.expired_certs.map((result: any, index: number) => (
                                    <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main" key={index}>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.crt_id}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.logged_at}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.not_before}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.not_after}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.common_name}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.matching_identities}</td>
                                        <td className="p-3 border border-main text-sm whitespace-pre-line break-words">{result.issuer_name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                            </>
                        }
                    </div>
                </>
            )}
        </div>
    )
}
export default Main