import { useState } from 'react';
import { MapPin, Search, Building, ArrowRight } from 'lucide-react';
const Main = () => {
    const [selectedRadius, setSelectedRadius] = useState('0.5');

    // Mock data for discovered companies
    const nearbyCompanies = [
        {
            name: "InnovateTech Ltd",
            distance: "0.3 miles",
            nearClient: "Clifford Chance",
            industry: "Software",
            size: "50-200",
            score: 85,
            status: "New Lead"
        },
        {
            name: "DataFlow Systems",
            distance: "0.5 miles",
            nearClient: "Bird & Bird",
            industry: "Data Analytics",
            size: "200-500",
            score: 78,
            status: "Researching"
        },
        {
            name: "AI Solutions Group",
            distance: "0.2 miles",
            nearClient: "Clifford Chance",
            industry: "Artificial Intelligence",
            size: "100-250",
            score: 92,
            status: "New Move-in"
        }
    ];

    // Law firm anchor points
    const lawFirms = [
        {
            name: "Clifford Chance",
            location: "London",
            areas: ["IP", "Corporate", "Tech"]
        },
        {
            name: "Bird & Bird",
            location: "City of London",
            areas: ["IP", "Tech", "Media"]
        }
    ];

    return (
        <div className="space-y-6 p-4">
            {/* Search Controls */}
            <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                <div className="flex items-center gap-2">
                    <MapPin className="w-5 h-5" />
                    <span className='font-bold'>Law Firm Area Scanner</span>
                </div>
                <div>
                    Discover potential leads near client law firms
                </div>
            </div>
            <div>
                <div className="flex gap-4 mb-6">
                    <div>
                        <label className="sr-only">Search radius</label>
                        <select value={selectedRadius} onChange={(e) => setSelectedRadius(e.target.value)} className="w-[180px] focus:outline-none w-full rounded-2xl py-2 mb-3 px-4 border-4 border-main text-primary">
                            <option value="0.5">0.5 miles</option>
                            <option value="1">1 mile</option>
                            <option value="2">2 miles</option>
                            <option value="5">5 miles</option>
                        </select>
                    </div>
                    <div>
                        <label className="sr-only">Industry Filter</label>
                        <select className="w-[180px] focus:outline-none w-full rounded-2xl py-2 mb-3 px-4 border-4 border-main text-primary">
                            <option value="tech">Technology</option>
                            <option value="biotech">Biotech</option>
                            <option value="fintech">Fintech</option>
                        </select>
                    </div>
                </div>

                <button className="flex items-center gap-2 px-4 py-2 bg-green text-white rounded-lg">
                    <Search className="w-4 h-4" />
                    Scan Area
                </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {lawFirms.map((firm, index) => (
                    <div key={index} className="p-4 border rounded-lg">
                        <div className="flex justify-between items-start mb-2">
                            <div>
                                <h3 className="font-medium">{firm.name}</h3>
                                <p className="text-sm text-gray-500">{firm.location}</p>
                            </div>
                            <div
                                className='bg-primary text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm'
                            >{selectedRadius} mile radius</div>
                        </div>
                        <div className="flex gap-2 mt-2">
                            {firm.areas.map((area, i) => (
                                <div key={i} className='bg-secondary text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm'>{area}</div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            {/* Discovered Companies */}
            <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                <div>
                    <div className="flex items-center gap-2">
                        <Building className="w-5 h-5" />
                        <span className='font-bold'>Discovered Companies</span>
                    </div>
                    <div>
                        Companies found in target areas
                    </div>
                </div>
                <div>
                    <div className="space-y-4">
                        {nearbyCompanies.map((company, index) => (
                            <div key={index} className="p-4 border rounded-lg hover:bg-gray-50">
                                <div className="flex justify-between items-start mb-3">
                                    <div>
                                        <h3 className="font-medium">{company.name}</h3>
                                        <p className="text-sm text-gray-500">{company.industry}</p>
                                    </div>
                                    <div className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm
                                        ${company.score >= 90 ? "bg-[#d1fae5] text-[#065f46]" :
                                            company.score >= 80 ? "bg-blue-100 text-blue-800" :
                                                "bg-yellow-100 text-yellow-800"}
                                    `}>
                                        Score: {company.score}
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-sm">
                                    <div>
                                        <span className="text-gray-500">Distance:</span>
                                        <br />
                                        {company.distance}
                                    </div>
                                    <div>
                                        <span className="text-gray-500">Near:</span>
                                        <br />
                                        {company.nearClient}
                                    </div>
                                    <div>
                                        <span className="text-gray-500">Size:</span>
                                        <br />
                                        {company.size}
                                    </div>
                                    <div>
                                        <span className="text-gray-500">Status:</span>
                                        <br />
                                        {company.status}
                                    </div>
                                </div>

                                <div className="flex gap-2 mt-4">
                                    <button className='flex items-center gap-2 px-4 py-2 bg-green text-white rounded-lg'>Research Company</button>
                                    <button className='flex items-center gap-2 px-4 py-2 bg-green text-white rounded-lg'>Add to Pipeline</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Recent Activity */}
            <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                <div>
                    <div className="flex items-center gap-2">
                        <ArrowRight className="w-5 h-5" />
                        <span className='font-bold'>Recent Activity</span>
                    </div>
                    <div>
                        Latest changes in target areas
                    </div>
                </div>
                <div>
                    <div className="space-y-2">
                        <div className="p-3 bg-blue-50 rounded-lg text-sm">
                            New company moved within 0.2 miles of Clifford Chance
                        </div>
                        <div className="p-3 bg-green-50 rounded-lg text-sm">
                            3 new startups registered in Bird & Bird area
                        </div>
                        <div className="p-3 bg-yellow-50 rounded-lg text-sm">
                            Office space changes detected near legal district
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Main