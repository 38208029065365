import { useCallback } from "react";
import { api } from "config";
import axios from "axios";
export const useFindLeads = () => {
    const objectToQueryParams = (obj: any) => {
        return new URLSearchParams(obj).toString();
    };
    const peopleSearch = useCallback(async (queryParams: any) => {
        try {
            const params = objectToQueryParams(queryParams);
            const { data } = await api.get("get_person_details?" + params);
            return data;
        }
        catch (e: any) {
            console.log(e)
        }
    }, [])
    return {
        peopleSearch
    }
}