import React, { useState } from 'react';
import {
    Calendar, PoundSterling, AlertTriangle, Check, Briefcase, FileText,
    Settings, TrendingUp, Globe, Shield, Scale, AlertCircle, RefreshCw,
    Trash2, Edit, Plus, Archive, Search, Download, BarChart2, ArrowLeft, ArrowRight
} from 'lucide-react';

// Custom color scheme
const THEME = {
    primary: 'bg-indigo-500 hover:bg-indigo-600',
    secondary: 'bg-teal-500 hover:bg-teal-600',
    accent: 'bg-amber-500',
    danger: 'bg-rose-500',
    warning: 'bg-amber-500',
    success: 'bg-emerald-500',
    text: {
        primary: 'text-indigo-900',
        secondary: 'text-gray-600',
        accent: 'text-amber-700'
    },
    border: {
        primary: 'border-indigo-500',
        secondary: 'border-teal-500',
        accent: 'border-amber-500'
    }
};

const STATUS_COLORS: any = {
    Active: 'bg-emerald-100 text-emerald-800',
    Pending: 'bg-amber-100 text-amber-800',
    Completed: 'bg-indigo-100 text-indigo-800'
};

// Case card component
const CaseCard = ({ caseData, onEdit, onDelete, onView }: any) => (
    <div className={`${caseData.priority === 'High' ? 'border-rose-500' :
        caseData.priority === 'Medium' ? 'border-amber-500' :
            'border-teal-500'
        } bg-white p-[25px] rounded-[5px] shadow-2xl`}
    >
        <div className="p-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="md:col-span-2">
                    <div className="flex justify-between items-start">
                        <div>
                            <h3
                                className="font-bold text-lg font-sans cursor-pointer hover:text-indigo-600"
                                onClick={() => onView(caseData.id)}
                            >
                                {caseData.title}
                            </h3>
                            <p className="text-sm text-gray-600 font-light">
                                Type: {caseData.type.replace('_', ' ')}
                            </p>
                        </div>
                        <div className="flex space-x-2">
                            <button
                                onClick={() => onEdit(caseData.id)}
                                className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                            >
                                <Edit className="h-4 w-4 text-indigo-500" />
                            </button>
                            <button
                                onClick={() => onDelete(caseData.id)}
                                className="p-2 hover:bg-rose-50 rounded-full transition-colors duration-200"
                            >
                                <Trash2 className="h-4 w-4 text-rose-500" />
                            </button>
                        </div>
                    </div>

                    <div className="mt-4 space-y-3">
                        <div className="flex flex-wrap gap-2">
                            <span className="px-3 py-1.5 bg-indigo-50 rounded-full text-sm font-medium text-indigo-700">
                                Budget: £{caseData.budget.toLocaleString()}
                            </span>
                            <span className="px-3 py-1.5 bg-teal-50 rounded-full text-sm font-medium text-teal-700">
                                Spent: £{caseData.spent.toLocaleString()}
                            </span>
                            <span className={`px-3 py-1.5 rounded-full text-sm font-medium ${caseData.spent > caseData.budget ? 'bg-rose-50 text-rose-700' :
                                caseData.spent > caseData.budget * 0.8 ? 'bg-amber-50 text-amber-700' :
                                    'bg-emerald-50 text-emerald-700'
                                }`}>
                                {((caseData.spent / caseData.budget) * 100).toFixed(1)}% Used
                            </span>
                        </div>
                    </div>
                </div>

                <div className="md:col-span-1 border-l pl-4">
                    <div className="space-y-3">
                        <div className="flex items-center justify-between">
                            <span className="text-sm text-gray-600 font-medium">Status</span>
                            <span className={`px-3 py-1 rounded-full text-xs font-semibold ${STATUS_COLORS[caseData.status]}`}>
                                {caseData.status}
                            </span>
                        </div>

                        <div className="flex items-center justify-between">
                            <span className="text-sm text-gray-600 font-medium">Priority</span>
                            <span className={`px-3 py-1 rounded-full text-xs font-semibold ${caseData.priority === 'High' ? 'bg-rose-100 text-rose-800' :
                                caseData.priority === 'Medium' ? 'bg-amber-100 text-amber-800' :
                                    'bg-teal-100 text-teal-800'
                                }`}>
                                {caseData.priority}
                            </span>
                        </div>

                        <div className="flex items-center justify-between">
                            <span className="text-sm text-gray-600 font-medium">Region</span>
                            <span className="text-sm font-medium">{caseData.region}</span>
                        </div>

                        <button
                            onClick={() => onView(caseData.id)}
                            className="w-full mt-2 px-3 py-1.5 text-center text-sm font-medium text-indigo-700 bg-indigo-50 rounded-lg hover:bg-indigo-100"
                        >
                            View Details
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

// Analytics component
const Analytics = ({ cases }: any) => {
    const totalBudget = cases.reduce((sum: any, c: any) => sum + c.budget, 0);
    const totalSpent = cases.reduce((sum: any, c: any) => sum + c.spent, 0);

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                <div className="p-6">
                    <div className="flex items-center space-x-2">
                        <PoundSterling className="h-5 w-5 text-indigo-500" />
                        <h3 className="font-semibold text-lg">Total Budget</h3>
                    </div>
                    <p className="text-3xl font-bold mt-4 text-indigo-900">
                        £{totalBudget.toLocaleString()}
                    </p>
                </div>
            </div>

            <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                <div className="p-6">
                    <div className="flex items-center space-x-2">
                        <TrendingUp className="h-5 w-5 text-teal-500" />
                        <h3 className="font-semibold text-lg">Total Spent</h3>
                    </div>
                    <p className="text-3xl font-bold mt-4 text-teal-900">
                        £{totalSpent.toLocaleString()}
                    </p>
                    <div className="mt-4 h-2 bg-gray-100 rounded-full">
                        <div
                            className={`h-full rounded-full ${(totalSpent / totalBudget) > 0.9 ? 'bg-rose-500' :
                                (totalSpent / totalBudget) > 0.7 ? 'bg-amber-500' :
                                    'bg-emerald-500'
                                }`}
                            style={{ width: `${Math.min((totalSpent / totalBudget) * 100, 100)}%` }}
                        />
                    </div>
                </div>
            </div>

            <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                <div className="p-6">
                    <div className="flex items-center space-x-2">
                        <BarChart2 className="h-5 w-5 text-amber-500" />
                        <h3 className="font-semibold text-lg">Active Cases</h3>
                    </div>
                    <p className="text-3xl font-bold mt-4 text-amber-900">
                        {cases.filter((c: any) => c.status === 'Active').length}
                    </p>
                    <p className="text-sm text-gray-600 mt-1">
                        of {cases.length} total cases
                    </p>
                </div>
            </div>
        </div>
    );
};

// Case Detail View
const CaseDetail = ({ caseId, onBack }: any) => {
    const [activeTab, setActiveTab] = useState('overview');

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                    <button
                        onClick={onBack}
                        className="p-2 hover:bg-gray-100 rounded-full"
                    >
                        <ArrowLeft className="h-5 w-5 text-gray-600" />
                    </button>
                    <h1 className="text-2xl font-bold text-indigo-900">Premium Brand Trademark Opposition</h1>
                </div>

                <div className="flex items-center space-x-2">
                    <span className={`px-3 py-1 rounded-full text-sm font-semibold ${STATUS_COLORS['Active']}`}>
                        Active
                    </span>
                    <span className="px-3 py-1 rounded-full text-sm font-semibold bg-rose-100 text-rose-800">
                        High
                    </span>
                </div>
            </div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">Select your country</label>
                <select className="focus:outline-none w-full rounded-2xl py-2 mb-3 px-4 border-4 border-main text-primary"
                    onChange={(e) => setActiveTab(e.target.value)}
                >
                    <option value="overview">Overview</option>
                    <option value="budget">Budget</option>
                    <option value="tasks">Tasks</option>
                    <option value="documents">Documents</option>
                    <option value="brand">Brand Strategy</option>
                </select>
            </div>
            <ul className="hidden text-sm font-medium text-center text-[#6B7280] rounded-lg shadow sm:flex">
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('overview')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] rounded-s-lg focus:outline-none ${activeTab === 'company' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}>Overview</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('budget')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'portfolio' ? 'active bg-[#F3F4F6]' : 'bg-white'}`}>Budget</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('tasks')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'tasks' ? 'active bg-[#F3F4F6]' : 'bg-white'}`}>Tasks</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('tasks')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'tasks' ? 'active bg-[#F3F4F6]' : 'bg-white'}`}>Tasks</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('documents')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'documents' ? 'active bg-[#F3F4F6]' : 'bg-white'}`}>Documents</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('brand')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'brand' ? 'active bg-[#F3F4F6]' : 'bg-white'}`}>Brand Strategy</a>
                </li>
            </ul>
            <div className='mt-5'>
            {activeTab === 'overview' &&
                <div>
                    <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                        <span className='font-bold'>Case Overview</span>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                            <div>
                                <h3 className="text-sm font-medium text-gray-600">Type</h3>
                                <p className="font-semibold">Trademark Action</p>
                            </div>
                            <div>
                                <h3 className="text-sm font-medium text-gray-600">Region</h3>
                                <p className="font-semibold">Europe</p>
                            </div>
                            <div>
                                <h3 className="text-sm font-medium text-gray-600">Budget</h3>
                                <p className="font-semibold">£75,000</p>
                            </div>
                            <div>
                                <h3 className="text-sm font-medium text-gray-600">Spent</h3>
                                <p className="font-semibold">£45,000</p>
                            </div>
                        </div>
                    </div>

                    <div className='bg-white p-[25px] rounded-[5px] shadow-2xl mt-6'>
                        <span className='font-bold'>Timeline</span>
                        <div className="space-y-6">
                            {[
                                { phase: 'Initial Assessment', status: 'Completed', date: '2024-01-15' },
                                { phase: 'Opposition Filed', status: 'Completed', date: '2024-02-01' },
                                { phase: 'Evidence Submission', status: 'Active', date: '2024-06-15' }
                            ].map((item, index) => (
                                <div key={index} className="flex items-start">
                                    <div className="mr-4 flex flex-col items-center">
                                        <div className={`h-6 w-6 rounded-full flex items-center justify-center ${item.status === 'Completed' ? 'bg-emerald-500' :
                                            item.status === 'Active' ? 'bg-indigo-500' :
                                                'bg-gray-300'
                                            }`}>
                                            {item.status === 'Completed' && <Check className="h-4 w-4 text-white" />}
                                        </div>
                                        {index < 2 && (
                                            <div className="h-8 w-px bg-gray-300 mt-1" />
                                        )}
                                    </div>
                                    <div>
                                        <h3 className="font-medium">{item.phase}</h3>
                                        <p className="text-sm text-gray-600">{item.date}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
            {activeTab === 'budget' &&
                <div>
                    <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                        <div>
                            <span className='font-bold'>Budget Management</span>
                        </div>
                        <div className="space-y-6">
                            <div>
                                <h3 className="text-sm font-medium text-gray-600 mb-2">Budget Overview</h3>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                    <div className="p-4 bg-gray-50 rounded-lg">
                                        <p className="text-sm text-gray-600">Total Budget</p>
                                        <p className="text-2xl font-bold text-indigo-900">£75,000</p>
                                    </div>
                                    <div className="p-4 bg-gray-50 rounded-lg">
                                        <p className="text-sm text-gray-600">Spent</p>
                                        <p className="text-2xl font-bold text-indigo-900">£45,000</p>
                                    </div>
                                    <div className="p-4 bg-gray-50 rounded-lg">
                                        <p className="text-sm text-gray-600">Remaining</p>
                                        <p className="text-2xl font-bold text-indigo-900">£30,000</p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h3 className="text-sm font-medium text-gray-600 mb-2">Budget Utilization</h3>
                                <div className="h-4 bg-gray-100 rounded-full">
                                    <div className="h-full rounded-full bg-amber-500" style={{ width: '60%' }} />
                                </div>
                                <p className="text-sm mt-1">60% used</p>
                            </div>

                            <div className="pt-4">
                                <h3 className="text-sm font-medium text-gray-600 mb-4">Budget Calculator</h3>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div>
                                        <div className="space-y-4">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                    Additional Budget Needed
                                                </label>
                                                <div className="flex items-center">
                                                    <span className="text-gray-600 mr-2">£</span>
                                                    <input
                                                        type="number"
                                                        defaultValue="10000"
                                                        className="w-full px-3 py-2 border rounded"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                    Estimated Success Rate
                                                </label>
                                                <select className="w-full px-3 py-2 border rounded">
                                                    <option value="high">High (80%)</option>
                                                    <option value="medium" selected>Medium (60%)</option>
                                                    <option value="low">Low (40%)</option>
                                                </select>
                                            </div>

                                            <button className={`mt-4 px-4 py-2 rounded ${THEME.primary} text-white`}>
                                                Calculate ROI
                                            </button>
                                        </div>
                                    </div>

                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <h4 className="font-medium mb-4">ROI Estimation</h4>
                                        <div className="space-y-3">
                                            <div className="flex justify-between">
                                                <span className="text-sm text-gray-600">Risk Value</span>
                                                <span className="font-medium">£150,000</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <span className="text-sm text-gray-600">Risk Reduction</span>
                                                <span className="font-medium">£90,000</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <span className="text-sm text-gray-600">ROI Ratio</span>
                                                <span className="font-medium text-emerald-600">9.0x</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {activeTab === 'tasks' &&
                <div>
                    <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                        <div className="flex justify-between">
                            <span className='font-bold'>Task Management</span>
                            <button className={`px-4 py-2 ${THEME.primary} text-white rounded-lg`}>
                                <span className="flex items-center">
                                    <Plus className="h-4 w-4 mr-2" />
                                    Add Task
                                </span>
                            </button>
                        </div>
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead className="bg-gray-50 text-left">
                                    <tr>
                                        <th className="px-4 py-3 text-sm font-medium text-gray-500">Task</th>
                                        <th className="px-4 py-3 text-sm font-medium text-gray-500">Assignee</th>
                                        <th className="px-4 py-3 text-sm font-medium text-gray-500">Date</th>
                                        <th className="px-4 py-3 text-sm font-medium text-gray-500">Status</th>
                                        <th className="px-4 py-3 text-sm font-medium text-gray-500">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    <tr className="hover:bg-gray-50">
                                        <td className="px-4 py-3 text-sm">Draft Opposition Letter</td>
                                        <td className="px-4 py-3 text-sm">John Smith</td>
                                        <td className="px-4 py-3 text-sm">2024-01-15</td>
                                        <td className="px-4 py-3 text-sm">
                                            <span className="px-2 py-1 rounded-full text-xs bg-emerald-100 text-emerald-800">
                                                Completed
                                            </span>
                                        </td>
                                        <td className="px-4 py-3 text-sm">
                                            <div className="flex space-x-2">
                                                <button className="text-indigo-600 hover:text-indigo-800">
                                                    <Edit className="h-4 w-4" />
                                                </button>
                                                <button className="text-rose-600 hover:text-rose-800">
                                                    <Trash2 className="h-4 w-4" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="hover:bg-gray-50">
                                        <td className="px-4 py-3 text-sm">Prepare Evidence Bundle</td>
                                        <td className="px-4 py-3 text-sm">Sarah Johnson</td>
                                        <td className="px-4 py-3 text-sm">2024-02-01</td>
                                        <td className="px-4 py-3 text-sm">
                                            <span className="px-2 py-1 rounded-full text-xs bg-indigo-100 text-indigo-800">
                                                In Progress
                                            </span>
                                        </td>
                                        <td className="px-4 py-3 text-sm">
                                            <div className="flex space-x-2">
                                                <button className="text-indigo-600 hover:text-indigo-800">
                                                    <Edit className="h-4 w-4" />
                                                </button>
                                                <button className="text-rose-600 hover:text-rose-800">
                                                    <Trash2 className="h-4 w-4" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {activeTab === 'documents' &&
                <div>
                    <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                        <div className="flex justify-between">
                            <span className='font-bold'>Documents</span>
                            <button className={`px-4 py-2 ${THEME.primary} text-white rounded-lg`}>
                                <span className="flex items-center">
                                    <Plus className="h-4 w-4 mr-2" />
                                    Upload Document
                                </span>
                            </button>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="p-4 border rounded-lg flex items-center">
                                <FileText className="h-8 w-8 text-indigo-500 mr-4" />
                                <div>
                                    <h3 className="font-medium">Opposition-Filing.pdf</h3>
                                    <p className="text-sm text-gray-600">Uploaded on 2024-02-15</p>
                                </div>
                            </div>
                            <div className="p-4 border rounded-lg flex items-center">
                                <FileText className="h-8 w-8 text-indigo-500 mr-4" />
                                <div>
                                    <h3 className="font-medium">Evidence-Bundle.pdf</h3>
                                    <p className="text-sm text-gray-600">Uploaded on 2024-02-14</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {activeTab === 'brand' &&
                <div>
                    <div className='bg-white p-[25px] rounded-[5px] shadow-2xl'>
                        <div>
                            <span className='font-bold'>Brand Strategy Integration</span>
                        </div>
                        <div className="space-y-6">
                            <div>
                                <h3 className="text-sm font-medium text-gray-600 mb-2">Strategic Objectives</h3>
                                <ul className="space-y-2">
                                    <li className="flex items-start">
                                        <Check className="h-5 w-5 text-emerald-500 mr-2 flex-shrink-0" />
                                        <span>Secure exclusive trademark rights in key markets</span>
                                    </li>
                                    <li className="flex items-start">
                                        <Check className="h-5 w-5 text-emerald-500 mr-2 flex-shrink-0" />
                                        <span>Prevent consumer confusion</span>
                                    </li>
                                    <li className="flex items-start">
                                        <Check className="h-5 w-5 text-emerald-500 mr-2 flex-shrink-0" />
                                        <span>Maintain brand positioning as premium offering</span>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h3 className="text-sm font-medium text-gray-600 mb-2">Brand Impact</h3>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                    <div className="p-4 bg-gray-50 rounded-lg">
                                        <p className="text-sm text-gray-600">Sentiment Score</p>
                                        <div className="flex items-center">
                                            <p className="text-xl font-bold text-indigo-900">65/100</p>
                                            <span className="ml-2 text-sm text-emerald-600">+5</span>
                                        </div>
                                    </div>
                                    <div className="p-4 bg-gray-50 rounded-lg">
                                        <p className="text-sm text-gray-600">Market Presence</p>
                                        <div className="flex items-center">
                                            <p className="text-xl font-bold text-indigo-900">Strong</p>
                                            <span className="ml-2 text-sm text-emerald-600">↑</span>
                                        </div>
                                    </div>
                                    <div className="p-4 bg-gray-50 rounded-lg">
                                        <p className="text-sm text-gray-600">Brand Protection</p>
                                        <div className="flex items-center">
                                            <p className="text-xl font-bold text-indigo-900">Medium</p>
                                            <span className="ml-2 text-sm text-amber-600">→</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            </div>
        </div>
    );
};

// Main Dashboard Component
const DisputeDashboard = () => {
    const [cases, setCases] = useState([
        {
            id: 1,
            title: 'Premium Brand Trademark Opposition',
            type: 'trademark_actions',
            status: 'Active',
            priority: 'High',
            budget: 75000,
            spent: 25000,
            risk: -150000,
            region: 'Europe'
        },
        {
            id: 2,
            title: 'Domain Portfolio Recovery',
            type: 'domain_actions',
            status: 'Pending',
            priority: 'Medium',
            budget: 35000,
            spent: 15000,
            risk: -50000,
            region: 'Global'
        },
        {
            id: 3,
            title: 'Marketplace Enforcement Campaign',
            type: 'online_enforcement',
            status: 'Active',
            priority: 'High',
            budget: 90000,
            spent: 60000,
            risk: -180000,
            region: 'Asia'
        },
        {
            id: 4,
            title: 'Social Media Brand Protection',
            type: 'online_enforcement',
            status: 'Active',
            priority: 'Medium',
            budget: 45000,
            spent: 30000,
            risk: -80000,
            region: 'Global'
        }
    ]);
    const [selectedCase, setSelectedCase] = useState(null);
    const [activeView, setActiveView] = useState('cases');

    const handleAddCase = () => {
        const newCase = {
            id: cases.length + 1,
            title: 'New Case',
            type: 'trademark_actions',
            status: 'Pending',
            priority: 'Medium',
            budget: 0,
            spent: 0,
            risk: 0,
            region: 'Global'
        };
        setCases([...cases, newCase]);
    };

    const handleEditCase = (id: any) => {
        console.log('Editing case:', id);
    };

    const handleDeleteCase = (id: any) => {
        setCases(cases.filter(c => c.id !== id));
    };

    const handleViewCase = (id: any) => {
        setSelectedCase(id);
    };

    if (selectedCase) {
        return <CaseDetail caseId={selectedCase} onBack={() => setSelectedCase(null)} />;
    }

    return (
        <div className="max-w-7xl mx-auto p-6 font-sans">
            <div className="flex justify-between items-center mb-8">
                <div>
                    <h1 className="text-3xl font-bold text-indigo-900">Dispute Management</h1>
                    <p className="text-lg text-gray-600 mt-1 font-light">
                        Managing {cases.length} active disputes
                    </p>
                </div>
                <div className="flex space-x-4">
                    <button
                        onClick={handleAddCase}
                        className={`flex items-center space-x-2 px-6 py-3 text-white rounded-lg shadow-sm text-white bg-primary`}
                    >
                        <Plus className="h-5 w-5" />
                        <span className="font-medium">New Case</span>
                    </button>
                </div>
            </div>

            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">Select your country</label>
                <select className="focus:outline-none w-full rounded-2xl py-2 mb-3 px-4 border-4 border-main text-primary"
                    onChange={(e) => setActiveView(e.target.value)}
                >
                    <option value="cases">Cases</option>
                    <option value="analytics">Analytics</option>
                </select>
            </div>
            <ul className="hidden text-sm font-medium text-center text-[#6B7280] rounded-lg shadow sm:flex">
                <li className="w-full focus-within:z-10" onClick={() => setActiveView('cases')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] rounded-s-lg focus:outline-none ${activeView === 'cases' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}>Cases</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveView('analytics')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeView === 'analytics' ? 'active bg-[#F3F4F6]' : 'bg-white'}`}>Analytics</a>
                </li>
            </ul>
            <div className='mt-5'>
            {activeView === 'cases' &&
                <div className="space-y-6">
                    {cases.map(caseData => (
                        <CaseCard
                            key={caseData.id}
                            caseData={caseData}
                            onEdit={handleEditCase}
                            onDelete={handleDeleteCase}
                            onView={handleViewCase}
                        />
                    ))}
                </div>
            }
            {activeView === 'analytics' &&
                <Analytics cases={cases} />
            }
            </div>
        </div>
    );
};

export default DisputeDashboard;